import { GoogleMap, useLoadScript, Marker, MarkerF } from "@react-google-maps/api"
import { useMemo, useState, useEffect } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import './maps.css'
import axios from "axios";
import { Input, Select, Alert } from 'antd';
const { Search } = Input;
const { Option } = Select;
const libary = ['places']
 
const GoogleMaps = ({ id }) => {

    // const { isLoaded } = useLoadScript({
    //     googleMapsApiKey: "AIzaSyBpt2QdmwMcSTH-4cnSyFFMSgfF-6tEf0o",
    //     libraries: libary
    // })

    const [selected, setSelected] = useState(null)
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions
    } = usePlacesAutocomplete(
        {
            debounce: 100, 
        }
    );

    const [selectedLat, setSelectedLat] = useState(12.9716)
    const [selectedLng, setSelectedLng] = useState(77.5946)
    const [userLocationLat, setUserLocationLat] = useState(12.9716)
    const [userLocationLng, setUserLocationLng] = useState(77.5946) 
    const [userLocation, setUserLocation] = useState(null);

    const [options, setOptions] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)

    const [buttonStatus, setButtonStatus] = useState(true)

    useEffect(() => {
        console.log("inside use effect")

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log("position.coords", position.coords)
                    setSelectedLat(parseFloat(position.coords.latitude))
                    setSelectedLng(parseFloat(position.coords.longitude))
                    setUserLocationLat(parseFloat(position.coords.latitude))
                    setUserLocationLng(parseFloat(position.coords.longitude))
                    setUserLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error("Error getting user location:", error.message);
                }
            );
        } else {
            console.error("Geolocation is not supported by your browser.");
        }

    }, [])

    const center = useMemo(() => {
        // Memoize the center based on userLocation
        return userLocation || { lat: 12.9716, lng: 77.5946 }; // Default center if userLocation is null
    }, [userLocation]);

    const handleSelect = async (address) => {
        console.log("address", address)
        // setValue(address, false);
        setValue(address)
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        console.log("lat and lng selected", lat, lng)

        setUserLocationLat(lat)
        setUserLocationLng(lng)
        setUserLocation({ lat: lat, lng: lng });

    }

    const handleOnClick = (e) => {
        console.log("event", e.latLng.lat(), e.latLng.lng())
        // setSelectedLat(e.latLng.lat())
        // setSelectedLng(e.latLng.lng())
        setUserLocationLat(e.latLng.lat())
        setUserLocationLng(e.latLng.lng())

    }

    const handleMyLocation = (e) => {

        console.log("set user location")
        setUserLocationLat(selectedLat)
        setUserLocationLng(selectedLng)
        setUserLocation({ lat: selectedLat, lng: selectedLng });

    }

    const handleConfirmLocation = () => {
        // e.preventDefault()
        if (!userLocationLat) {
            return alert('Please select a location')
        }
        const data = {
            id: id,
            latitude: userLocationLat,
            longitude: userLocationLng
        }

        setIsDisabled(true)
        axios.post('https://sheet.best/api/sheets/85118316-d893-45b9-b59f-4097a5681f31', data)
            .then(response => {
                // setIsDisabled(false)
                // alert('Location saved') 
                console.log(response); 
            })
        setButtonStatus(false)
    }

    const handleChange = (e) => {
        setValue(e)
        console.log("e", e)
        console.log("data address", data)
        const options = data.map(({ place_id, description }) => {
            return {
                label: description,
                value: place_id
            }
        })
        setOptions(options)
    }


    return (
        <div>


            {/* {isLoaded ? */}
                <>
                    {isDisabled && 
                     <Alert message="Location saved successfuly" type="success" showIcon closable closeIcon={true}/>
                    }
                    <div style={{ height: '60%' }}>
                        <GoogleMap zoom={20}
                            options={{ disableDefaultUI: true, zoomControl: false, fullscreenControl: false }}
                            center={center} mapContainerStyle={{ width: '100%', height: '73vh' }} onClick={handleOnClick}>
                            {/* <MarkerF position={{ lat: userLocationLat, lng: userLocationLng }} icon={'/myLocation.png'}/> */}
                            <MarkerF position={{ lat: userLocationLat, lng: userLocationLng }} style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} icon='location-pin2.png'/>

                        </GoogleMap>
                        <div className=" current-location-container" onClick={handleMyLocation}>
                            <img
                                width={"35px"}
                                height={"35px"}
                                src="/my-location.png"
                                style={{ backgroundColor: '#fff', padding: '2px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                            />
                        </div>
 
                        <div
                            className="places-container"
                        >
                            {/* <Combobox onSelect={handleSelect}>
                <ComboboxInput  
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={!ready}
                    className="combobox-input"
                    placeholder="Search" /> 
                    <ComboboxPopover>
                        <ComboboxList style={{backgroundColor:'#ffffff'}}>
                            {status === "OK" && data.map(({place_id, description})=> 
                            <ComboboxOption key={place_id} value={description}/>)}
                        </ComboboxList>
                    </ComboboxPopover>
                </Combobox> */}
                            <Select
                                showSearch
                                placeholder="Search"
                                optionFilterProp="children"
                                onChange={handleSelect}
                                onSearch={handleChange}
                                // filterOption={handleSelect}
                                style={{ width: 300, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius:'6px'}}
                                notFoundContent={null}
                                suffixIcon={null}
                                disabled={!ready}
                                value={value?value:null}
                                size="large"
                                // allowClear={true}   
                            // options= {data.map(({place_id, description}) => (
                            //     {"label": "hello", "value":"hello"}
                            // ))}  
                            // options={options}
                            >
                                {status === "OK" && data.map(({ place_id, description }) => (
                                    <Option width={200} key={place_id}  value={description}>{description}</Option>
                                ))}
                            </Select>
                        </div> 
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px', height: '20%', padding: '20px' }}><button style={{
                        padding: '10px',
                        backgroundColor: isDisabled ? '#CCCCCC' : '#4C702A',
                        border: 0,
                        // boxShadow: '5px 5px #888888',
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                        borderRadius: '5px',
                        color: '#ffffff',
                        fontSize: '16px',
                        // fontFamily: 'Myriad Pro Regular',
                        // fontStyle: 'normal',
                        fontWeight: 500,
                    }}

                        disabled={isDisabled}
                        onClick={buttonStatus?handleConfirmLocation:''}>{buttonStatus?'Confirm Location':'Done'}</button></div>
                </>
                 {/* :
                 <div>Loading...</div>} */}


        </div>
    )
}
export default GoogleMaps;
