import logo from './logo.svg';
import './App.css';
// import Maps from './components/maps';
import GoogleMaps from './components/GoogleMaps';
import { useParams } from 'react-router-dom';


function App() {


  const { id } = useParams();

  console.log("id", id)


  return (
    <div style={{background: 'cornsilk', width:'100%'}}>

      <div style={{ height: '25%', display: 'flex', justifyContent: 'space-around' }}>
        <img
          width='35%'
          height='100%'
          src="/Logo.webp"
          style={{ padding: '10px', background: 'cornsilk' }}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: '#2A2A2A', fontSize: '14px', margin: '10px' }}>Please tap on the exact location</div>
      </div>
      <div
        style={{
          background: '#2A2A2A',
          height: '0.5px',
        }}
      />
      <div style={{ height: '70%' }}>
        <GoogleMaps id={id} />
      </div>
    </div>
  );
}

export default App;
